export const locale = {
    lang: 'es',
    data: {
        GENERAL: {
            MEDIA: {
                AVERAGE_LIMIT_EXCEEDED: {
                    TITLE: 'Límite promedio excedido',
                    MESSAGE: 'Se ha excedido el límite promedio, contacte al laboratorio',
                    SPACE: {
                        ONE: 'Se ha excedido el límite promedio, espacio disponible: {{space}}, tamaño del archivo: {{size}}',
                        OTHER: 'Se ha excedido el límite promedio, espacio disponible: {{space}}, tamaño total de los archivos: {{size}}'
                    },
                    BUTTONS: {
                        CONFIRM: 'Entendido',
                    }
                }
            }
        }
    },
};
