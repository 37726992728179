export const locale = {
    lang: 'pt',
    data: {
        GENERAL: {
            MEDIA: {
                AVERAGE_LIMIT_EXCEEDED: {
                    TITLE: 'Limite de média excedido',
                    MESSAGE: 'O limite de média foi excedido, entrar em contato com o laboratório',
                    SPACE: {
                        ONE: 'O limite de média foi excedido, espaço disponível: {{space}}, tamanho do arquivo: {{size}}',
                        OTHER: 'O limite de média foi excedido, espaço disponível: {{space}}, tamanho total dos arquivos: {{size}}'
                    },
                    BUTTONS: {
                        CONFIRM: 'Entendi',
                    }
                }
            }
        }
    },
};
