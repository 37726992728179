export const locale = {
    lang: 'en',
    data: {
        GENERAL: {
            MEDIA: {
                AVERAGE_LIMIT_EXCEEDED: {
                    TITLE: 'Average limit exceeded',
                    MESSAGE: 'The average limit has been exceeded, contact the laboratory',
                    SPACE: {
                        ONE: 'The average limit has been exceeded, available space: {{space}}, file size: {{size}}',
                        OTHER: 'The average limit has been exceeded, available space: {{space}}, total file size: {{size}}'
                    },
                    BUTTONS: {
                        CONFIRM: 'I understand',
                    }
                }
            }
        }
    },
};
