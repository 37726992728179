import { signal } from '@angular/core';
import { FuseTranslationLoaderService } from 'app/core/translate/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as portuguese } from './i18n/pt';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { first } from 'rxjs';
import { Util } from 'app/core/helpers/util';

export const translateAppGeneral = signal<any>({});

export const populateTranslateGeneral = (fuseTranslationLoaderService: FuseTranslationLoaderService, translateService: TranslateService) => {
    fuseTranslationLoaderService.loadTranslations(portuguese, english, spanish);
    translateService
        .get('GENERAL')
        .pipe(first())
        .subscribe((response: any) => {
            translateAppGeneral.set(response);
        });
};

export function translateGeneral(
    key: string,
    params?: {
        [key: string]: string;
    },
    type?: 'one' | 'other' | 'zero',
) {
    return Util.translateKey(translateAppGeneral(), key, params, type);
}
